.home {
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  background-color: #E0E4E7;
  position: relative;
}

.home-container {
  position: fixed;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.home-quotes {
  position: relative;
  max-width: 400px;
  height: 100%;
  width: 100%;
  transform: translateX(60%);
}

.home-bg {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    object-position: top left;
    width: 100%;
    height: 100%;
  }
}

@include media("<desktop") {
  .home-bg {
    img {
      object-position: 10% 0;
    }
  }
  .home-container {
    width: 95%;
    min-width: auto;
  }
  .home-quotes {
    transform: translateX(48%);
  }
}

@include media("<tablet") {
  .home-container {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    padding: 2rem 0;
    padding: 0;
  }

  .home-bg {
    height: auto;
    width: 100%;
    position: relative;
    img {
      object-position: 0 0;
      height: 100vh;
      width: 100%;
    }
  }

  .home-quotes {
    display: none;
    max-width: 90%;
    transform: translateX(0);
    margin: 0 auto;

  }
}

.slick-dots {
  text-align: right !important;
  bottom: auto !important;
  top: -30px;
  li.slick-active {
    button:before {
      color: $red !important;
    }
  }

}
