.calendar {
  background: $pale;
  position: relative;
  opacity: 0;
  transform: translateY(15px);
}

.events-future {
  padding: 0 0 2rem 0;

}

.events-past {
  padding: 0 0 2rem 0;

}

.events-divider {
  width: 100%;
  height: 1px;
  width: 100%;
  background: $gray;
  display: block;
  margin: 0 0 6rem 0;
}
.past-title {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0 3rem 0;
}
