.event-inner {
  padding: 4rem 0;
  display: flex;
  justify-content: flex-start;
}

.event-date {
  margin: 0 2rem 0 0;
  text-align: right;
}

.event-day {
  font-size: 4rem;
  color: $red;
  font-weight: 800;
  margin: 0;
  line-height: 4rem;
}

.event-month {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2rem;
}

.event-info {
  margin: 0 0 0 2rem;
  padding: 0 0 0 3rem;
  border-left: 1px solid $gray;
}

.event-orchestra {
  font-size: 3rem;
  font-weight: 400;
  margin: 0 0 1rem 0;
}

.event-title {
  font-size: 2rem;
  font-weight: 400;
  margin: 0 0 2rem 0;
  font-style: italic;
}

.event-soloists {
  margin: 0 0 2rem 0
}


.event-program {
  margin: 0 0 2rem 0;
  color: $gray;
  font-size: 1.6rem;
  line-height: 2rem;
  li {
    margin: 0 0 1rem 0;
  }
}

.event-location, .event-time {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  margin: 0 0 1rem 0;
}


@include media("<=tablet") {
  .event-inner {
    padding: 3rem 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .event-date {
    display: flex;
    margin: 0 0 2rem 0;
    padding: 0 0 2rem 0;
    text-align: left;
    align-items: center;
    border-bottom: 1px solid $gray;
    width: 100%;
  }

  .event-day {
    margin: 0 1rem 0 0;
  }

  .event-info {
    margin: 0;
    padding: 0;
    border-left: none;
  }

  .event-title {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 700;
  }
}

