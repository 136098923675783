.review {
  margin: 0 0 4rem 0;
  padding: 0 0 4rem 0;
  border-bottom: 1px solid $gray-light;
}

.review-inner {
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.review-url {
  color: $red;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0 0 2rem 0;
  display: block;
}

.review-brand {
  padding: 0 2rem 0 0;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}
.review-source {
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
  line-height: 4rem;
  text-transform: uppercase;
}

.review-logo {
  max-width: 100%;
  height: auto;
  margin: 1rem 0 0 0;
  width: auto;
}

.review-info {
  padding: 0 0 0 3rem;
   max-width: 600px;
}
.review-magazine {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 600;
  margin: 0 0 2rem 0;
}

.review-date {
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 600;
  margin: 0 0 3rem 0;
  color: $gray;
  text-transform: uppercase;
}

.review-title {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 600;
  margin: 0 0 2rem 0;
}

.review-text {
  margin: 0 0 2rem 0;
}

.review-author {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
}

.review-pdf {
  color: $red;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    margin: 0 0 0 0.6rem;
    fill: $red;;
  }
}

@include media("<tablet") {

  .review {
    &:last-child {
      border-bottom: 0;
      margin: 0;
      padding: 0;
    }
  }

  .review-inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .review-url {
    font-size: 1.6rem;
  }

  .review-brand {
    display: none;
  }

  .review-magazine {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .review-info {
    padding: 0;
    max-width: 100%;
  }

  .review-title {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
