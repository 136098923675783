.biography {
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #B0B0BC;
  position: relative;
  box-sizing: border-box;
}

.biography-container {
  position: relative;
  width: 60%;
  left: 40%;
  z-index: 10;
  max-width: 700px;
  padding: 3rem;
  opacity: 0;
  transform: translateY(15px);
}

.biography-languages {
  padding: 0 0 2.5rem 0;
  button {
    margin: 0 2rem 0 0;
    border: none;
    background: $red;
    color: white;
    padding: 0 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
  }
}

.biography-header {
  margin: 0 0 2rem 0;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid darken($gray-light, 15%);
  h2 {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  p {
    font-size: 1.8rem;
    line-height: 3rem;
    color: $gray;
    margin: 0 0 2rem 0;
    &:last-child {
      margin: 0;
    }
  }
}

.biography-content {
  max-height: 3000px;
  p {
    margin: 0 0 2rem 0;
  }
}
.biography-bg {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
  }
}

@include media("<=hd") {
  .biography-container {
    left: 40%;
  }
  .biography-bg {
    img {
      object-position: 10% 0;
    }
  }
}

@include media("<=desktop") {
  .biography-bg {
    img {
      object-position: 14% 0;
    }
  }
}

@include media("<=tablet") {
  .biography {
    background-color: lighten(#B0B0BC, 15%);
  }
  .biography-container {
    position: relative;
    width: 100%;
    left: 0;
    max-width: 100%;
    padding: 0;
  }
  .biography-header {
    h2 {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  .biography-languages {
    padding: 2rem 0;
  }

  .biography-bg {
    // height: 100vh !important;
    // width: auto !important;
     position: relative;
    img {
      object-position: contain;
      height: 100vh;
      width: 100%;
    }
  }
}
