.contact {
  position: relative;
  opacity: 0;
  transform: translateY(15px);

}

.contact-inner {
  display: flex;
  justify-content: space-between;

}

.contact-left {
  width: 40%;
  padding: 0 4rem 0 8rem;
  box-sizing: border-box;
}

.contact-right {
  width: 60%;
  max-height: 900px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 20%;
  }
}
.contact-section {
  margin: 0 0 4rem 0;
  h2 {
    margin: 0 0 1rem 0;
    font-size: 2.2rem;
    color: black;
    font-weight: 600;
  }

  h3 {
    color: black;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 600;
    display: block;
    padding-top: 2rem;
  }

  p, a {
    display: block;
    color: $gray-medium;
    line-height: 2.2rem;
    margin: 0 0 4px 0;
    font-size: 1.6rem;
    &.contact-manager {
      font-size: 2rem;
      font-weight: 700;
      margin: 0;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}

@include media("<=hd") {
  .contact-left {
    padding: 0 6rem 0 4rem;
  }
}

@include media("<=desktop") {
  .contact-left {
    width: 45%;
  }
  .contact-right {
    width: 55%;
    img {
      object-position: 0 40%;
    }
  }

}

@include media("<tablet") {
  .contact-inner {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .contact-left {
    width: 100%;
    padding: 4rem 3rem;
  }

  .contact-right {
    order: -1;
    width: 100%;
    img {
      max-height: 500px;
      object-position: left 20%;
    }
  }

  .contact-section {
    margin: 0 0 6rem 0;
    h2 {
      margin: 0 0 2rem 0;
    }

    p, a {
      line-height: 2.2rem;
      margin: 0 0 2rem 0;
    }
  }
}

@include media("<phone") {
  .contact-right {
    img {
      max-height: 450px;
      object-position: left 37%;
    }
  }
}
