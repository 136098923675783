.videos {
   opacity: 0;
  transform: translateY(15px);
}

.videos-content {
  padding: 4rem 0;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.video {
  width: 48%;
  margin: 0 0 4rem 0;
}

.video-frame {
  margin: 0 0 1rem 0;
  background: black !important;
  iframe {
    width: 100%;
    height: 22vw;
    max-height: 300px;
    background: black !important;
  }
}

.video-title {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  color: black;
  margin: 0 0 1rem 0;
  min-height: 50px;
}

.video-icon {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: $red;
  svg {
    width: 30px;
    margin: 0 1rem 0 0;
    path {
      fill: $red;
    }
  }
}

@include media("<desktop") {
  .video-list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 900px;
  }

  .video {
    width: 100%;
  }
  .video-title {
     min-height: auto;
  }
  .video-frame {
    iframe {
      height: 50vw;
      max-height: 430px;
    }
  }
}

@include media("<phone") {
  .video {
    margin: 0 0 4rem 0;
  }

  .video-frame {
    iframe {
      max-height: 100%;
    }
  }
}
