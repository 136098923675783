.pull-wrapper {
  display: none;
  position: absolute;
  top: 3.2rem;
  right: 3rem;
  z-index: 25;
}

.pull {
  width: 25px;
  height: 19px;
  cursor: pointer;
  transition: top 0.3s ease;
  display: none;
  mix-blend-mode: difference;
  .pull1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 0) rotate(0);
    transition: transform 0.3s ease;
  }
  .pull2 {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }
  .pull3 {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 0) rotate(0);
    transition: transform 0.3s ease;
  }
}

.pull-open {
  .pull1 {
    transform: translate(7px, -5px) rotate(45deg);
    transition: transform 0.3s ease;
  }
  .pull2 {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }
  .pull3 {
    transform: translate(-13px, 7px) rotate(-45deg);
    transition: transform 0.3s ease;
  }
}

@include media("<desktop") {
  .pull-wrapper {
    display: block;
  }
  .pull {
    display: block;
    top: 2.3rem;
  }
}
@include media("<tablet") {
  .pull-wrapper {
    top: 2.3rem;
    right: 3rem;
  }
}

@include media("<phone") {
  .pull-wrapper {
    top: 1.9rem;
    right: 2rem;
  }
}
